import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import AvatarImg from '../../component/assets/image/avatar.jpg';
import { Avatar, styled, Typography } from '@mui/material';
import './manager.css'
import StatusSelect from '../users/StatusSelect';
import AssignUser from '../users/AssignUser';
import RegisterNewUser from '../users/RegisterNewUser';
import EditableBucket from '../EditBucket/EditableBucket';
import { getUserList } from '../../component/service/apis/user/User.api';

const ManagerList = () => {


    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openUserProfile, setOpenUserProfile] = useState(false);
    const [userid, setUserID] = useState('')

    const handleUserList = async () => {
        const res = await getUserList();
        if (res) {
            const managers = res.filter(user => user.roleName === 'manager');
            setUserList(managers);
        }
    }

    useEffect(() => {
        handleUserList();
    }, [])

    const columns = [
        {
            name: "id",
            label: "UserID",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "S.No",
            label: "S.No",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <div>{dataIndex + 1}</div>;
                },
            },
        },
        {
            name: "employeeID",
            label: "Employee Code",
            options: {
                filter: false,
                sort: true,
                display: true
            },
        },
        {
            name: "name",
            label: "Employee",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const employee = tableMeta.rowData;

                    const handleClickUser = (id) => {
                        setOpenUserProfile(true)
                        setUserID(id)
                    }

                    return (
                        <React.Fragment>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleClickUser(employee[0])}>
                                <Avatar alt={employee[1]} src={AvatarImg || employee[10]} style={{ marginRight: '20px' }} />
                                <div>
                                    <Typography variant="body1">{employee[2]}</Typography>
                                    <Typography variant="body2" color="textSecondary">{employee[3]}</Typography>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "roleName",
            label: "Role",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "positionName",
            label: "Position",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "departmentName",
            label: "Department",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "totalLeaves",
            label: "Leaves",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => (
                    <EditableBucket
                        value={value}
                        userID={tableMeta.rowData[0]} // Assuming userID is at index 0 in your rowData
                        handleUserList={handleUserList}
                        onUpdate={(updatedValue) => {
                            // Handle the updated value as needed
                            return updatedValue
                        }}
                    />
                ),
            },
        },
        {
            name: "createdAt",
            label: "Joining Date",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const employee = tableMeta.rowData;
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <StatusSelect status={value} userID={employee[0]} handleUserList={handleUserList} />
                        </div>
                    );
                }
            },
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
    ];





    // const CustomToolbar = () => {
    //     return (
    //         <React.Fragment>
    //             <Tooltip title='Register new Manager'>
    //                 <IconButton onClick={() => setOpen(true)}>
    //                     <AddIcon />
    //                 </IconButton>
    //             </Tooltip>
    //         </React.Fragment>
    //     )
    // }

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        // customToolbar: () => <CustomToolbar />,

    };

    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Employees List"}
                data={userList}
                columns={columns}
                options={options}
            />
            <RegisterNewUser
                open={open}
                onHide={() => setOpen(false)}
                setOpen={setOpen}
                handleUserList={handleUserList}
            />
            <AssignUser
                open={openUserProfile}
                onClose={() => setOpenUserProfile(false)}
                userid={userid}
            />
        </React.Fragment>
    );
}

export default ManagerList;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});