import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import AvatarImg from '../../component/assets/image/avatar.jpg';
import {
    Avatar,
    Button,
    Chip, styled, Tooltip, Typography
} from '@mui/material';
import './user.css'
import StatusSelect from './StatusSelect';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RegisterNewUser from './RegisterNewUser';
import MyProfile from '../myprofile/MyProfile';
import EditableBucket from '../EditBucket/EditableBucket';
import { getUserList } from '../../component/service/apis/user/User.api';
import { enrollFinger } from '../../component/service/apis/attendance/attendance.api';

const getColor = (selected) => {
    let opacity = '0.5';
    return selected === 'active' ? 'rgba(76, 175, 80, ' + opacity + ')' : 'rgba(255, 193, 7, ' + opacity + ')';
};

const role = localStorage.getItem('role');

const UserList = () => {


    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openUserProfile, setOpenUserProfile] = useState(false);
    const [userid, setUserID] = useState('')

    const handleUserList = async () => {
        const res = await getUserList();
        if (res) {
            // const users = res.filter(user => user.role === 'user');
            setUserList(res);
        }
    }

    useEffect(() => {
        handleUserList();
    }, [])

    const handleEnrollFinger = (userID) => {
        enrollFinger(userID).then((res) => {
            res && handleUserList()
        })
    }

    const columns = [
        {
            name: "id",
            label: "UserID",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "employeeID",
            label: "Employee Code",
            options: {
                filter: false,
                sort: true,
                display: true
            },
        },
        // {
        //     name: "S.No",
        //     label: "S.No",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRenderLite: (dataIndex) => {
        //             return <div>{dataIndex + 1}</div>;
        //         },
        //     },
        // },
        {
            name: "name",
            label: "Employee",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const employee = tableMeta.rowData;
                    const handleClickUser = (id) => {
                        setOpenUserProfile(true)
                        setUserID(id)
                    }

                    return (
                        <React.Fragment>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleClickUser(employee[0])}>
                                <Avatar alt={employee[1]} src={employee[4] || AvatarImg} style={{ marginRight: '20px' }} />
                                <div>
                                    <Typography variant="body1">{employee[2]}</Typography>
                                    <Typography variant="body2" color="textSecondary">{employee[3]}</Typography>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "roleName",
            label: "Role",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "positionName",
            label: "Position",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "departmentName",
            label: "Department",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "totalLeaves",
            label: "Leaves",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => (
                    role === 'super admin' ? <EditableBucket
                        value={value}
                        userID={tableMeta.rowData[0]} // Assuming userID is at index 0 in your rowData
                        handleUserList={handleUserList}
                        onUpdate={(updatedValue) => {
                            // Handle the updated value as needed
                            return updatedValue
                        }}
                    /> : <Chip label={value} />
                ),
            },
        },
        {
            name: "joiningDate",
            label: "Joining Date",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const employee = tableMeta.rowData;
                    return (
                        (role === 'super admin' || role === 'admin') ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <StatusSelect status={value} userID={employee[0]} handleUserList={handleUserList} />
                            </div>
                            :
                            <Chip label={value} sx={{ backgroundColor: getColor(value) }} />
                    );
                }
            },
        },
        {
            name: "biometricId",
            label: "Biometric Status",
            options: {
                filter: true,
                sort: false,
                filterOptions: {
                    names: ["Enrolled", "Unenrolled"],
                    logic: (value, filters) => {
                        if (filters.length === 0) return false;
                        const isEnrolled = value !== null;
                        const showEnrolled = filters.indexOf("Enrolled") >= 0;
                        const showUnenrolled = filters.indexOf("Unenrolled") >= 0;
                        
                        if (showEnrolled && !showUnenrolled) return !isEnrolled;
                        if (!showEnrolled && showUnenrolled) return isEnrolled;
                        return false;
                    }
                },
                customBodyRender: (value, tableMeta) => {
                    const employee = tableMeta.rowData;
                   
                    return (
                        value !== null ?
                            <Chip label={"Enrolled"} sx={{ backgroundColor: 'rgba(76, 175, 80,0.5 )' }} />
                            :
                            <Button variant="contained" color='warning' onClick={() => handleEnrollFinger(employee[0])}>Enroll</Button>
                    );
                }
            },
        },

    ];

    const CustomToolbar = () => {
        return (
            <React.Fragment>
                <Tooltip title='Register new user'>
                    <IconButton onClick={() => setOpen(true)}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        )
    }

    const options = {
        // filterType: 'checkbox',
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50,],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => (role === 'super admin' || role === 'admin') && <CustomToolbar />,
    };

    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Employees List"}
                data={userList}
                columns={columns}
                options={options}
            />
            <RegisterNewUser
                open={open}
                onHide={() => setOpen(false)}
                handleUserList={handleUserList}
                setOpen={setOpen}
            />
            <MyProfile
                open={openUserProfile}
                onClose={() => setOpenUserProfile(false)}
                userid={userid}
                handleUserList={handleUserList}
            />
        </React.Fragment>
    );
}

export default UserList;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});