import { notifyError, notifySuccess } from "../../../notify/Notify";
import { postRequestWithFetch } from "../../FetchingMethod";

const getTodayAttendance = async (data) => {
    try {
        const res = await postRequestWithFetch(`attendance/list`, data);
        if (res && res.status === true) {
            return res.data;
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}

const enrollFinger = async (userID) => {
    try {
        // Sending a GET request to the Arduino server
        const res = await postRequestWithFetch(`user/biometric/enroll/${userID}`);
        if (res && res.status === true) {
            notifySuccess({ Message: res.message });
            return res.status;
        } else {
            notifyError({ Message: res.error })
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

const deleteFinger = async (userID) => {
    try {
        // Sending a GET request to the Arduino server
        const res = await postRequestWithFetch(`user/biometric/delete/${userID}`);
        if (res && res.status === true) {
            notifySuccess({ Message: "Finger Deleted Successfully!" });
        } else {
            notifyError({ Message: res.error })
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        // setEnrollmentStatus('Enrollment failed. See console for details.');
    }
}

const updateAttendanceStatus = async (attendanceId, status) => {
    const body = {
        attendanceId: attendanceId
    }
    try {
        const res = await postRequestWithFetch(`attendance/update/${status}`, body);
        if (res && res.status === true) {
            notifySuccess({ Message: res.message })

        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}

const getAttendanceReportInJson = async (body, userId) => {
    try {
        const res = await postRequestWithFetch(`attendance/report/json/${userId || ""}`, body);
        if (res && res.status === true) {
            return res.data;
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}

const getAttendanceReportInExcel = async (body, userId, empName) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/attendance/report/excel/${userId || ""}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(body),
        });

        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `attendance-report-${body.month}-${body.year}${empName ? `-${empName}` : ""}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } catch (err) {
        notifyError({ Message: err });
    }
}

// const getAllAttendance = async (body) => {
//     try {
//         const res = await postRequestWithFetch(`dd/all`, body);
//         if (res && res.status === true) {
//             notifySuccess({ Messasge: 'Record Found' })
//             return res.data;
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }
// }


export {
    getTodayAttendance,
    enrollFinger,
    deleteFinger,
    updateAttendanceStatus,
    getAttendanceReportInJson,
    getAttendanceReportInExcel
}