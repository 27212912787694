import { useEffect, useState } from "react";
import { styled } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';

import FullScreenDialog from "../../component/dialogBox/FullScreenDialog";
import { getAttendanceReportInExcel, getAttendanceReportInJson } from "../../component/service/apis/attendance/attendance.api";

const UserReport = ({ open, setOpen, month, year, userId }) => {
    const [userReports, setUserReports] = useState([]);
    const [empName, setEmpName] = useState("");
    const [empCode, setEmpCode] = useState("");

    const handleDownloadReport = async () => {
        await getAttendanceReportInExcel({ month, year }, userId, empName);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleReportList = async () => {
        const res = await getAttendanceReportInJson({ month, year }, userId);
        if (res && res.length > 0) {
            setEmpName(res[0]?.["Emp Name"]);
            setEmpCode(res[0]?.["Emp Code"]);
            setUserReports(() => res[0]?.report_data);
        } else {
            return handleClose()
        }
    }

    useEffect(() => {
        handleReportList();
    }, [userId])

    const columns = [
        {
            name: "Att. Date",
            Label: "Att. Date",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "inTime",
            Label: "In Time",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "OutTime",
            Label: "OutTime",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "shift",
            Label: "Shift",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "S. InTime",
            Label: "S. InTime",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "S. OutTime",
            Label: "S. OutTime",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Work Dur.",
            Label: "Work Dur",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "OT",
            Label: "OT",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Tot. Dur.",
            Label: "Tot. Dur.",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "LateBy",
            Label: "LateBy",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "EarlyGoingBy",
            Label: "EarlyGoingBy",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Status",
            Label: "Status",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Punch Records",
            Label: "Punch Records",
            options: {
                filter: false,
                sort: false,
            },
        },
    ]

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50,],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    }

    const CustomToolbar = () => {
        return (
            <div style={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }} className="custom-toolbar">
                <IconButton onClick={handleDownloadReport} sx={{ color: 'primary.main', verticalAlign: 'middle' }}>
                    <DescriptionIcon />
                </IconButton>
            </div>
        );
    }

    const body = <StyledMUIDataTable
        title={`Attenance Report: ${empName} (${empCode})`}
        data={userReports}
        columns={columns}
        options={options}
    />

    return (
        <>
            <FullScreenDialog
                open={open}
                onClose={handleClose}
                body={body}
            />
        </>
    )
}

export default UserReport

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});
