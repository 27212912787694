import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { styled } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';

import { getAttendanceReportInExcel, getAttendanceReportInJson } from "../../component/service/apis/attendance/attendance.api"
import UserReport from "./userReport";

const Reports = () => {
    const [currentDate] = useState(() => new Date());
    const [reportList, setReportList] = useState([]);
    const [month, setMonth] = useState(() => currentDate.getMonth() + 1);
    const [year, setYear] = useState(() => currentDate.getFullYear());
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState(null)

    // fetch all user reports
    const handleReportList = async () => {
        const res = await getAttendanceReportInJson({ month, year });
        if (res) {
            setReportList(res);
        }
    }

    const handleDownloadReport = async () => {
        await getAttendanceReportInExcel({ month, year });
    }

    useEffect(() => {
        handleReportList();
    }, [month, year])

    const columns = [
        {
            name: "Emp Code",
            Label: "Emp. Code",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Emp Name",
            Label: "Emp. Code",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return <>
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            setUserId(reportList[tableMeta?.rowIndex]?.userId)
                            setOpen(true)
                        }}>
                            {value}
                        </div>
                    </>;
                },
            },
        },
        {
            name: "Total Duration",
            Label: "Total Duration",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Present Days",
            Label: "Present Days",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "AbsentDays",
            Label: "Absent Days",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Holidays",
            Label: "Holidays",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Weekly Off",
            Label: "Weekly Off",
            options: {
                filter: false,
                sort: false,
            },
        },
    ]

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50,],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    }

    const CustomToolbar = () => {
        return (
            <div style={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }} className="custom-toolbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl variant="outlined" style={{ marginRight: '1rem', width: '7rem' }}>
                        <InputLabel size="small" id="month-select-label">Month</InputLabel>
                        <Select
                            labelId="month-select-label"
                            size="small"
                            id="month-select"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            label="Month"
                            variant="outlined"
                        >
                            <MenuItem value="1">January</MenuItem>
                            <MenuItem value="2">February</MenuItem>
                            <MenuItem value="3">March</MenuItem>
                            <MenuItem value="4">April</MenuItem>
                            <MenuItem value="5">May</MenuItem>
                            <MenuItem value="6">June</MenuItem>
                            <MenuItem value="7">July</MenuItem>
                            <MenuItem value="8">August</MenuItem>
                            <MenuItem value="9">September</MenuItem>
                            <MenuItem value="10">October</MenuItem>
                            <MenuItem value="11">November</MenuItem>
                            <MenuItem value="12">December</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ width: '5.5rem' }}>
                        <InputLabel size="small" id="year-select-label">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            size="small"
                            id="year-select"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            label="Year"
                            variant="outlined"
                        >
                            <MenuItem value="2024">2024</MenuItem>
                            <MenuItem value="2025">2025</MenuItem>
                            <MenuItem value="2025">2026</MenuItem>
                            <MenuItem value="2025">2027</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <IconButton onClick={handleDownloadReport} sx={{ color: 'primary.main' }}>
                    <DescriptionIcon />
                </IconButton>
            </div>
        );
    }

    return (
        <>
            <StyledMUIDataTable
                title={"Attendance Report"}
                data={reportList}
                columns={columns}
                options={options}
            />
            <UserReport
                open={open}
                userId={userId}
                month={month}
                year={year}
                setOpen={setOpen}
            />
        </>
    )
}

export default Reports

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});
